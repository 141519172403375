<template>
  <div class="home">


    <div class="titlebar">
      <el-image style="height: 90px" :src="require('../assets/titlebar/titlebar.png')"></el-image>
      <div style="font-weight: 900;font-size: 45px;height: 90px;line-height: 120px;baseline-shift: baseline">
        沈阳凯铭智慧科技有限公司
      </div>

    </div>
    <div class="aboutus">
      <div class="subtitle">
        <div>
          Company Overview
        </div>
        <div> 公司概况</div>
      </div>
      <!--      <div class="about">-->
      <div class="about">
      <el-row >
        <el-col :xs="24" :sm="24" :md="18"  class="about_t">
          <div class="txt">
            公司以大数据技术为核心，结合公司骨干人员在物联网及交通等领域的长期能力积累，进行自主投入研发，历经三年时间形成大数据平台、智慧交通系统、信息化监测系统等共计16项自主知识产权，并在成果转化和推广中得到了市场的认可，在许昌市智慧交通建设、海关信息化系统智能监测建设等过程中实现了社会效益与经济效益。在产学研领域凯铭智慧公司与沈阳工业大学展开了深入的合作，在新能源等领域已逐步开始进行项目落地；与东北大学在AI人工智能算法等领域的合作已经在深入探讨中。
          </div>
          <div class="txt"> 公司为年轻的高科技创新企业，于2023年被评为国家级高新技术企业。</div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="6" class="about_p">
          <el-image style="padding-left: 40px" :src="require('../assets/images/gx.jpg')"></el-image>
        </el-col>
      </el-row>
      </div>
    </div>
    <!--    </el-row>-->
    <!--    </div>-->
    <div class="zz">
      <div class="subtitle">
        <div>
          Intellectual Property

        </div>
        <div> 知识产权</div>
      </div>
      <div class="about">
        <div style="text-align: center;width: 90vw">
          <div class="container"   ref="container">

              <img v-for="item in 16" :key="item" style="height: 500px;width: 100%" :src="require('../assets/images/rz'+item+'.png')"/>

          </div>
<!--          <div ref="wrapper">  /* 这里是父盒子*/-->
<!--            <ul class="cont" ref="cont">  /* 这里是子盒子，即滚动区域*/-->
<!--              <li class="cont-item" v-for="item of recommendList" :key="item.id">-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--      <el-carousel :interval="4000" type="card" height="800px" >-->

<!--        <el-carousel-item v-for="item in 16" :key="item">-->

<!--          <el-image style="height: 800px" :src="require('../assets/images/rz'+item+'.png')"></el-image>-->
<!--        </el-carousel-item>-->
<!--      </el-carousel>-->
      </div>
      </div>
    </div>
    <div class="foot">
      <span>&copy;2024 沈阳凯铭智慧科技有限公司</span>
      <span><a href="https://beian.miit.gov.cn/">辽ICP备2023006935号-1</a></span>
    </div>

  </div>
</template>

<script>
import axios from "axios"
// import Echart from "@/echart";
// import geoChart from "@/echart/geomap/index";

// import SwiperItem from "../components/Swiper/item.vue"
export default {
  // components: {Carousel,CarouselItem},
  data() {
    return {
      // feets: [],
      // filterfeets: [],
      // years: null,
      // locs:[]
    };
  },
  mounted() {
    const container = this.$refs.container; // 获取容器元素
    let isDown = false, // 是否按下鼠标
        startX, // 鼠标起始位置
        scrollLeft; // 滚动条位置

    container.addEventListener('mousedown', (e) => {
      isDown = true;
      startX = e.pageX - container.offsetLeft;
      scrollLeft = container.scrollLeft;
    });

    container.addEventListener('mouseup', () => {
      isDown = false;
    });

    container.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - container.offsetLeft;
      const walk = (x - startX) * 2;
      container.scrollLeft = scrollLeft - walk;
    });
    // this.getlocs()
    // this.getallfeet()
  }
  ,
  methods: {
    filters() {
      // alert(this.years)
      if (this.years === "" || this.years === null) {
        this.filterfeets = this.feets
      } else {
        this.filterfeets = this.feets.filter(item =>
            item.stime.substring(0, 4) === this.years
        )
      }
    },
    getallfeet() {
      axios.get(this.api.baseUrl + '/allfeet').then(res => {
        this.feets = res.data
        this.filterfeets = this.feets
        // console.log(this.feets)
        // this.$ref.geoChart.setfdata(this.feets)
      }).catch(err => {
        console.log("获取数据失败" + err);
      })
    },
    getlocs() {
      axios.get(this.api.baseUrl + '/getloc').then(res => {
        this.locs = res.data
      }).catch(err => {
        console.log("获取数据失败" + err);
      })
    }

  }
}
</script>
<style  >
body{
  margin: 0;
  padding: 0;
}
/*.home{*/
/*  !*display: block;*!*/
/*  margin: 0;*/
/*  padding: 0;*/
/*}*/
.titlebar {
  display: flex;
  background-color: rgb(197 197 197 / 67%);
  /*height: 160px;*/
  padding: 40px 120px;
  margin: 0;
  gap: 40px;
}
.container{
  display: flex; /* 将子元素横向排列 */
  white-space: nowrap; /* 避免子元素在父元素内换行 */
  overflow-x: auto; /* 显示横向滚动条 */
  gap: 20px;height: 500px;
}
.subtitle {
  width: 420px;
  font-size: 28px;
  font-weight: 800;
  margin-left: 100px;
  padding-top: 40px;
  padding-bottom: 10px;
  border-bottom: black solid 2px;
}

.about {
  /*display: flex;*/
  margin: 20px 20px;
  padding: 20px 80px;

}

.about_t {
  /*width: 70vw;*/
}

.txt {
  font-size: 20px;
  line-height: 35px;
  text-indent: 40px;
  padding-right: 40px;

  /*font-size-adjust: inherit;*/

}



.foot {
  background-color: #99999960;
  height: 40px;
  align-self: center;
  display: flex;
  justify-content: center;
  gap: 40px;
  margin: 0;
  padding: 0;

}
.foot span{

    line-height: 40px;

}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

</style>
