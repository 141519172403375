import Vue from 'vue'
import VueRouter from 'vue-router'
// import manage from "@/pages/manage";
import home from "@/pages/home"
Vue.use(VueRouter)

// //添加以下代码
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }
const router = new VueRouter({
    //tips:不想要 #（锚点）就添加下面代码
    mode: 'history',
    //4.配置路由的path和组件
    routes: [
        {
            path:'/',
            component:home
        },
        // {
        //     path: '/manage',
        //     name: 'manage',
        //     component: manage
        // },
        // {
        //     path: '/home',
        //     name: 'home',
        //     component: home
        // },
    ]
})
//5.导入路由实例
export default router
