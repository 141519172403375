import Vue from 'vue';
// import { createApp } from 'vue'
import App from './App.vue'
import echarts from 'echarts'
import router from './router/index'
import Element from 'element-ui'
import api from './api'
// import moment from "moment";
Vue.prototype.$echarts = echarts;
// import Swiper from 'swiper';
// import 'swiper/css/swiper.min.css'
import 'element-ui/lib/theme-chalk/index.css';//使用
// import 'element-ui/lib/theme-default/index.css';
Vue.use(Element);
// Vue.use(Swiper);
Vue.prototype.api = api
// Vue.use(moment)
// App.use(Router)
new Vue({

    router,
    render: (h) => h(App),
}).$mount('#app');

